import * as React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout.js"
import Seo from "../components/seo.js"
import { ImgixGatsbyImage } from '@imgix/gatsby'

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);


const BlogPage = ({ data, location, pageContext }) => {

  return (
        <Layout>
        <Seo
          pagetitle="ブログ"
          pagedesc="ヘッドレスCMSやJamstackについての情報を紹介します。"
          pagepath={location.pathname}
        />
      <div>
        <div className="mainTtlBg">
          <div className="mainTtlArea container">
            <h1>ヘッドレスCMSブログ<span>BLOG</span></h1>
          </div>{/* /mainphArea */}
        </div>{/* /mainphAreabg */}
      {/* パン屑 start */}
      <div className="breadArea">
        <ul className="breadLt container clearfix" itemScope itemType="https://schema.org/BreadcrumbList">
          <li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
            <Link to="https://jamstack-sg.com/" itemProp="item"><span itemProp="name">ヘッドレスCMS特設サイト</span></Link>
            <meta itemProp="position" content={1} />
          </li>
          <li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
            <Link to="https://jamstack-sg.com/blog/" itemProp="item"><span itemProp="name">ブログ</span></Link>
            <meta itemProp="position" content={2} />
          </li>
        </ul>
      </div>
      {/* パン屑 end */}
          <section>
            <div className="postList containerS">
              <ul className="blogLi clearfix">
                {data.allMicrocmsBlog.edges.map(( { node } ) => (
                  <li>
                    <Link to={`/blog/${node.slug}/`}>
                      <article className="post" key={node.id}>
                        <div className="ph">
                          <ImgixGatsbyImage
                            src={node.eyecatch.url}
                            imgixParams={{ auto: ["format", "compress"] }}
                            layout="constrained"
                            width={573}
                            sourceWidth={node.eyecatch.width}
                            sourceHeight={node.eyecatch.height}
                            style={{ height: "100%" }}
                        />
                        <time>{dayjs.utc(node.publishDate).tz('Asia/Tokyo').format('YYYY年M月D日')}</time></div>
                      <h3>{node.title}</h3>
                      </article>
                    </Link>
                  </li>
                ))}
              </ul>

              <ul className="pagenation clearfix">
                {!pageContext.isFirst && (
                  <li className="prev">
                    <Link to={
                      pageContext.currentPage === 2
                        ? `/blog/`
                        : `/blog/${pageContext.currentPage - 1}/`
                      }
                      rel="prev">
                      <span>前のページ</span>
                    </Link>
                  </li>
                )}
                {!pageContext.isLast && (
                  <li className="next">
                    <Link to={`/blog/${pageContext.currentPage + 1}/`} rel="next">
                      <span>次のページ</span>
                    </Link>
                  </li>
                )}
              </ul>

            </div>
          </section>
      </div>
        </Layout>
  )
}

export const query = graphql`
query($skip: Int!, $limit: Int!) {
  allMicrocmsBlog(sort: {order: DESC, fields: publishDate},
    skip: $skip,
    limit: $limit,
    ) {
    edges {
      node {
        title
        id
        slug
        eyecatch {
          height
          url
          width
        }
        publishDate
      }
    }
  }
}`

export default BlogPage